import { getBackgroundCSS, getBorderCSS, getSpaceCSS, getTypoCSS } from '../../Components/Helper/getCSS';

const Style = ({ attributes, clientId }) => {
	const { columnGap, rowGap, background, padding, border, captionBG, captionTypo, captionColor } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${getTypoCSS(captionTypo)?.googleFontLink ? `@import url(${getTypoCSS(captionTypo)?.googleFontLink});` : ''}

		#ifbInstagramFeed-${clientId} .ifbInstagramFeed{
			${getBackgroundCSS(background)}
			padding: ${getSpaceCSS(padding)};
			${getBorderCSS(border)}
		}
		#ifbInstagramFeed-${clientId} .ifbInstagramFeed .galleryHeader{
			margin-bottom: ${rowGap};
		}
		#ifbInstagramFeed-${clientId} .ifbInstagramFeed .ifbGallery{
			grid-gap: ${rowGap} ${columnGap};
		}
		#ifbInstagramFeed-${clientId} .ifbInstagramFeed .galleryItem figcaption{
			${getBackgroundCSS(captionBG)}
		}
		#ifbInstagramFeed-${clientId} .ifbInstagramFeed .galleryItem figcaption p{
			${getTypoCSS(captionTypo)?.styles}
			color: ${captionColor};
		}
		`.replace(/\s+/g, ' ')
	}} />
}
export default Style;